.warnBack {
    display: flex;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(255, 86, 86, 0) 0%, rgba(255, 80, 80, 0.15) 100%);
    border: 1px solid #814545;
}

.warnText {
    margin: 10px;

    font-family: 'Radiance';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;

    background: linear-gradient(180deg, #FF8E8E 0%, #5C5C5C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;


}

/*
.overlayWrap {
    max-width: 100px;
    border-radius: 4px;
    padding: 1px;
    border-image-slice: 1;
    border-image-source: radial-gradient(100% 210.13% at 0% 100%, #9A69EB 0%, rgba(154, 105, 235, 0) 100%) 
}
*/

.overlayBack{
    border-radius: 4px;
    border-image-slice: 4;
    border-image-source: radial-gradient(100% 210.13% at 0% 100%, #9A69EB 0%, rgba(154, 105, 235, 0) 100%);
    background: radial-gradient(228.57% 817.14% at 29.37% 0%, #332949 0%, #272727 100%)
    , rgba(184, 164, 225, 0.2);

}

.overlayText{
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Radiance';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.75);

}
